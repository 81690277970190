<template>
  <div
    class="claim-reward bg-nColorYellow"
    v-if="businessName && businessLocation"
  >
    <div class="mainContent gap-4 justify-items-center text-center">
      <div class="flex flex-col justify-center items-center pt-8">
        <img :src="logo" class="w-1/3" />
        <div
          class="text-black text-5xl text-center mt-2"
          v-if="businessName && businessLocation"
        >
          <span class="font-ZuumeExtraBold business-name" ref="businessNameRef">
            {{ businessName }}
          </span>
          <div class="text-black text-base font-ZuumeSemiBold">
            {{ businessLocation }}
          </div>
        </div>
      </div>

      <div class="font-ZuumeMedium text-lg mx-8 mt-4 uppercase">
        <strong>Ask the manager for a code</strong>
      </div>
      <div class="flex justify-center gap-4 mt-4">
        <input
          v-for="i in 4"
          :key="i"
          @keydown.space.prevent=""
          maxlength="1"
          class="codeText"
          type="number"
          @input="handleInput(i, $event)"
          @keydown.delete="movePrev(i)"
          :ref="'code' + i"
        />
      </div>
      <div v-show="errorMsg" class="text-red-500 mx-8 mt-4">
        <p class="text-sm">{{ errorMsg }}</p>
      </div>
    </div>
    <div class="font-InterRegular text-sm mx-8 mt-4">
      Enter the <strong>4-digit code</strong> given to you by the
      <strong>Business</strong> to verify that you claimed the freebie.
    </div>
    <div class="mx-4">
      <div class="flex items-center gap-1 mt-4 mb-2">
        <img :src="require('@/assets/images/bullet-star.svg')" class="w-6" />
        <p class="font-ZuumeRegular text-lg">
          Freebies cannot be exchanged or redeemed for cash.
        </p>
      </div>
      <div class="flex items-center gap-1 mt-4 mb-2">
        <img :src="require('@/assets/images/bullet-star.svg')" class="w-6" />
        <p class="font-ZuumeRegular text-lg">
          Freebie to be claimed before 11 pm on the same day.
        </p>
      </div>
    </div>
    <div class="w-full absolute bottom-14">
      <div class="flex justify-center">
        <button
          @click.prevent="validateCode()"
          class="shadow-border-orange-right bg-black rounded-3xl w-5/6 text-white text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          Submit
        </button>
      </div>
    </div>
    <!--Absolute bottom takes REM value-->
    <Copyright :absoluteBottom="'1'" />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Copyright from "@/components/elements/Copyright.vue";

export default {
  name: "ClaimFreebie",
  components: { Copyright },
  data() {
    return {
      businessName: this.$store.getters.businessName,
      businessLocation: this.$store.getters.businessLocation,
      logo: this.$store.getters.businessLogo,
      resendDisabled: false,
      countdown: 60,
      errorMsg: null,
      code: "",
    };
  },
  created() {
    ApiService.post(apiResource.claimFreebie.validateCodeStatus, {
      business_slug: this.$route.params.business,
      schedule_id: this.$route.params.schedule_id,
    })
      .then(() => {})
      .catch(() => {
        this.goBackToLobby();
      });
  },
  mounted() {
    this.resizeText();
  },
  methods: {
    handleInput(index, event) {
      const inputValue = event.target.value;

      if (inputValue.length > 1) {
        event.target.value = inputValue.charAt(0);
      }

      if (inputValue.length === 1 && index < 4) {
        const nextInputRef = `code${index + 1}`;
        const nextInput = this.$refs[nextInputRef][0];
        nextInput.focus();
      }

      if (inputValue.length === 0 && index > 1) {
        const prevInputRef = `code${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }

      this.updateCodeValue();
    },
    movePrev(index) {
      if (index > 1) {
        const currentInputRef = `code${index}`;
        const currentInput = this.$refs[currentInputRef][0];
        const prevInputRef = `code${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];

        currentInput.value = ""; // Clear current input value
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }
    },
    updateCodeValue() {
      const codeInputValues = Array.from(
        document.querySelectorAll(".codeText")
      ).map((input) => input.value);

      this.code = codeInputValues.join("");
    },
    validateCode() {
      ApiService.post(apiResource.claimFreebie.validateCode, {
        business_slug: this.$route.params.business,
        schedule_id: this.$route.params.schedule_id,
        code: this.code,
      })
        .then(() => {
          this.$router.replace({
            name: "Congratulations",
            params: {
              business: this.$route.params.business,
              schedule_id: this.$route.params.schedule_id,
            },
          });
        })
        .catch((error) => {
          if (error.response.data.msg === "Invalid Code") {
            this.errorMsg = "This is an invalid code";
          } else {
            this.goBackToLobby();
          }
        });
    },
    startResendCounter() {
      this.resendDisabled = true;
      this.countdown = 60;

      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.resendDisabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    goBackToLobby() {
      this.$router.push({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    resizeText() {
      const businessNameElement = this.$refs.businessNameRef;
      const containerWidth = businessNameElement.clientWidth;
      const originalText = this.businessName;
      let fontSize = 30; // Default font size
      const maxFontSize = 50; // Maximum font size

      // Create a temporary element to measure the width of the text
      const tempElement = document.createElement("span");
      tempElement.style.visibility = "hidden";
      tempElement.style.fontSize = fontSize + "px";
      tempElement.textContent = originalText;
      document.body.appendChild(tempElement);

      // Reduce font size until it fits within the container width
      while (tempElement.clientWidth > containerWidth && fontSize > 0) {
        fontSize--;
        tempElement.style.fontSize = fontSize + "px";
      }

      // Remove the temporary element
      document.body.removeChild(tempElement);

      // Set the final font size for the business name element
      businessNameElement.style.fontSize =
        Math.min(fontSize, maxFontSize) + "px";
    },
  },
};
</script>

<style scoped lang="postcss">
.claim-reward {
  height: 100dvh;
}
.textInput {
  @apply border border-black rounded-lg block appearance-none focus:bg-nColorOrange focus:outline-none bg-transparent w-80 py-2.5 px-2 placeholder-black text-xs font-InterMedium;
}
.codeText {
  @apply bg-nColorYellow rounded font-ZuumeSemiBold text-black border border-black text-5xl focus:outline-none focus:bg-nColorOrange text-center w-12 uppercase py-1 px-1;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>